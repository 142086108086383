// ref : https://github.com/Ashish1101/GrapgQlLearning/blob/de67e55326a48aafd94b0d4f6e3e9bff5d4fba20/client/src/graphql/Mutaions.js

import { useLazyQuery, useMutation} from "@apollo/client";
import { Alert, Button, FormGroup, Grid, Input, InputLabel, TextField} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { userDatas } from "../../../hoc/cache";

import { createCardMGQL, generateUserM, getUserByName, getUserByToken, signUpLoginM } from "../../../hoc/gql";

declare global {
  interface Window {
    kakao: any;
  }
}

const emailRegex = /^(([^<Fragment>()[\]\\.,;:\s@"]+(\.[^<Fragment>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Login = () => {

    const [message , setMessage] = useState('')
    const [email , setEmail] = useState('');
    const [password , setPassword] = useState('')
    const [name , setName] = useState('')
    const [existUser, setExistUser] = useState(false); 
    const [loggedIn, setLoggedIn] = useState(false); //check this  userDatas().uid !== ''); 
    const [validEmail, setValidEmail] = useState(false);  

    let index = localStorage.getItem('index') as string;
    if( index === null ) index = '';

    useEffect( ()=>{
      if( index.length > 10 ) {
        setEmail(index+'@jinju2.co');
        checkEmail(index+'@jinju2.co');
      }
    },[]);

    const [ getUserByNameHandler, getUserByNameRequest] = useLazyQuery(getUserByName, {
      onCompleted: (data) => {
        if( data === undefined) return;
        if( data.getUserByName.user !== null ){
          setExistUser(true);
          setMessage('로그인 하세요.');
        } 
        else{
          setExistUser(false);
          if( email !== '' ) setMessage('사용자 등록을 하세요.');
        } 
      }}
    )
    
    const [signUpLogin , signUpLoginRequest] = useMutation(signUpLoginM , {
      //here i am doing something after the mutaion finished
      onCompleted(data) {
      if(data.signUpLoginM.success === true ) {
        setMessage('');
          //setLogin( data.signUpLoginM.id === ''? 2:1);
  
          //localStorage.setItem('userToken' , data.signUpLoginM.token);
          //console.log('set uid by signin:'+data.signUpLoginM.id);
          //userDatas({...userDatas(), uid:'login'});
  
          //localStorage.setItem('exName' , email);
          //localStorage.setItem('exID' , data.signUpLoginM.id);
        }
        else{
          setMessage(data.signUpLoginM.error);
        }
      }
    });
  
    const [generateUser , generateUserRequest] = useMutation(generateUserM , {
      //here i am doing something after the mutaion finished
      onCompleted(data) {
      if(data.generateUserM.success === true ) {
  
        setMessage('');
        //setLogin( data.generateUserM.id === ''? 2:1);          
        //userDatas({...userDatas(), uid:data.generateUserM.id});
        //localStorage.setItem('exName' , email);
        //localStorage.setItem('exID' , data.generateUserM.id);
          //localStorage.setItem('userToken' , data.generateUserM.token);
      }
      else{
        setMessage(data.generateUserM.error);
      }
      }
    });
  
 
    const checkEmail = (email:string) =>{
  
      if( /^[A-Z0-9._%+-]+@[A-Z0-9{}.-]+\.[A-Z]{2,4}$/i.test(email) ){
        getUserByNameHandler({variables: {username: email},notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        });
  
        setValidEmail(true);
      }
      else{
        setMessage('email 주소를 입력하세요.');
        setValidEmail(false);
      }
  
      setEmail(email);
    }
    const submitLogin = (e:any) => {
      
      if(password===''){
        setMessage('password를 입력하세요.');
        return;
      }

      signUpLogin({variables: {username:email , password:password}}).then((result) => {
        const {data : {signUpLoginM  : {success , token , id}}} = result
        //console.log('user token ',token)
        if( success === true ){
          //console.log('set uid by signin ex:'+id);
          userDatas({...userDatas(), uid:'login'});
          localStorage.setItem('userToken',token);
          //localStorage.setItem('userID',id); 
          //localStorage.setItem('exID',id); 
          setLoggedIn(true);  
        }
      }).catch(err => {
        //console.log('login error',signUpLoginRequest.error)
        //console.log(err)
       })
    }
    // {"cases":[],"actions":[["",{"cat":4,"type":10,"action":["../Form/User",""],"rules":""},1,""]]}'id:'64093a9e46a03c0336b7b2ee'
    const submitSignup = (e:any) => {
      const dataJson = {
        cases:[],
        actions:[["",{"cat":4,"type":10,"action":["../Form/User",""],"rules":""},1,""]], // cat:6(form) - type:1(assessment result)
        content:{name:name}, 
      }
  
      let userdata = JSON.stringify(dataJson);
      let uname = email;
      generateUser({variables: {username:uname , password:password, userdata:userdata}}).then((result) => {
      
        const {data : {generateUserM  : {success , token , id}}} = result

        //console.log('set uid by signup ex:'+id);
        userDatas({...userDatas(), uid:'signup'});
      // //console.log('user token ',token)
        //console.log('user id ',id)
        //localStorage.setItem('exID',id); 
        //localStorage.setItem('userID' , id);
        localStorage.setItem('userToken' , token);
        setLoggedIn(true);    
      }).catch(err => {
        //console.log('login error',createUserError)
        //console.log(err)
      })
  
    }
  
    return (
      <Grid container direction="row" justifyContent="center" alignItems="flex-end" spacing={2}>
        {!loggedIn && <Fragment>
            <Grid item xs={12}>
              {index === '' && <FormGroup>
                <InputLabel htmlFor="exampleEmail">Email 주소를 입력하세요.</InputLabel>
                <Input type="email" onChange={(e) => checkEmail(e.target.value)} value={email} name="email" />
              </FormGroup>}
              {index !== '' && <FormGroup>
                <InputLabel htmlFor="exampleEmail">전화 번호</InputLabel>
                {index}
              </FormGroup>}
            </Grid>
            {validEmail && <Fragment>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel htmlFor="exampleEmail">Password</InputLabel>
                  <Input type="password" onChange={(e) => setPassword(e.target.value)} value={password}  name="password" />
                </FormGroup>
              </Grid>
              { existUser && <Grid item xs={12}>
                  <Button color="success" variant="contained"  onClick={submitLogin} >login</Button>
                </Grid>}
              { !existUser && <Fragment>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel htmlFor="exampleEmail">Name</InputLabel>
                    <Input type='text' onChange={(e) => setName(e.target.value)} value={name}  name="name" />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button color="success" variant="contained"  onClick={submitSignup} >SignUp</Button>
                </Grid>
              </Fragment>}
            </Fragment>}
        </Fragment>}

        { message !== '' && !loggedIn && <Grid item xs={12}><Alert severity="info" >{message}</Alert></Grid>}
      </Grid>
    );
  }

export default Login;  
/**
        {loggedIn && <Grid item xs={12}>
          <Button color="success" variant="contained" onClick={submitLogout} >logout</Button>
        </Grid>
        }
                    <Grid item xs={12}>
              <FormGroup>
                <InputLabel htmlFor="examplePhone">전화번호를 입력하세요.</InputLabel>
                <Input type="phone" onChange={(e) => checkPhone(e.target.value)} value={phone} name="phone" />
              </FormGroup>
            </Grid>

 */
  /*
    //const [phone , setPhone] = useState('');
    //const [validPhone, setValidPhone] = useState(false);
    const checkPhone = (phone:string) =>{
  
      if( /001-[0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/i.test(phone) ){
        getUserByNameHandler({variables: {username: phone},notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        });
  
        setValidPhone(true);
      }
      else{
        setMessage('전화번호를 입력하세요.');
        setValidPhone(false);
      }
  
      setPhone(phone);
    }
*/
