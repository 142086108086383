import { useReactiveVar } from "@apollo/client";
import { MoodBadOutlined, SentimentDissatisfiedOutlined, SentimentNeutralOutlined, SentimentSatisfiedAlt } from "@mui/icons-material";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useRef,Fragment } from "react";
import styled from "styled-components";
import { resultDatas, taskDatas, userDatas } from "../../hoc/cache";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Title = styled.h1`
  text-align: center;
  font-family: "NanumC", "Roboto", sans-serif;
  font-weight: bold;
  color: #000;
  margin-bottom: 0px;
  margin-top: 10px;
`;


const SelectList = ({ cardid, cases,selectCase,switchAlign,switchDir, vert}:{cardid:string, cases:[], selectCase:any, switchAlign:string, switchDir:number, vert:boolean}) => {

    //const { datas:[], selectCase, switchAlign, switchDir} = props;
    const { width : cWidth, height: cHeight } = useReactiveVar(userDatas);
    const { id : id, data: data, } = useReactiveVar(resultDatas);
    const prevCardID = useRef('');

    let fH = cHeight / 25 | 0;
    if( fH > 42 ) fH = 42;

    function getIcon(idx:number){ 
        // SentimentDissatisfiedOutlined / SentimentNeutralOutlined
        if( idx === 0 )
            return <SentimentSatisfiedAlt viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 1 )
            return <SentimentNeutralOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 2 ) 
            return <MoodBadOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 3 ) 
            return <SentimentDissatisfiedOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;  
        else
            return <Fragment></Fragment>;      
    }

    let bspace = {xs: 1, sm: 1, md: 1};
    if( vert )
        bspace = {xs: 5, sm: 3, md: 2};

    let select = "";    
    if( taskDatas().history[cardid] !== undefined ){ // && taskDatas().history[cardid].outputs.length > 1 )
        let o = taskDatas().history[cardid].outputs;
        if( o.length > 1)
            select = taskDatas().history[cardid].select;
    } 
    prevCardID.current = '';    

    // check position
    let switchs:any = [];
    let nMenu = 0;

    cases.map((item:any,idx) => {
        let title = item.title;
        let nIcon = 9;
        let nRule = 0;
        let nType = 0;
        let nX = 0;
        let nY = 0;
    
        let cmds = [...title.matchAll(/\[([^\]]+)\]+/g)];

        if( cmds.length !== 0 ){
            let cmd = cmds[0][1].split(';');
            if( cmd[0].length > 2){ // for keyword
                if( cmds[0][0] === '[User]' ){
                    if( userDatas().uid !== '') title = '내 카드';
                    else title = '사용자 등록';
                }
            }
            else if( cmd[0] === ''){ // for icon
                nIcon = parseInt(cmds[0][0][2],10);
                title =  item.title.substring(4);
            }
            else if( cmd[0] === 't'){ // for position 
                let pos = cmd[1].split(',');
                nType = 1;
                nX = parseInt(pos[0],10);
                if( isNaN(nX) ) nX = pos[0];
                nY = parseInt(pos[1],10);
                title =  item.title.substring(cmds[0][0].length)
                nMenu++;
            }
            else if(cmd[0] === 'r'){
                nRule = parseInt(cmd[1],10);
                if( nRule === 10 && localStorage.getItem(`${1}`) === '1') nRule = -1;
                if( nRule === 11 && localStorage.getItem(`${2}`) === '1') nRule = -1;
                if( nRule === 12 && localStorage.getItem(`${3}`) === '1') nRule = -1;
                if( nRule === 13 && localStorage.getItem(`${4}`) === '1') nRule = -1;
                if( nRule === 14 && localStorage.getItem(`${5}`) === '1') nRule = -1;
                if( nRule === 15 && localStorage.getItem(`${51}`) === '1') nRule = -1;
                if( nRule === 16 && localStorage.getItem(`${52}`) === '1') nRule = -1;
                if( nRule === 17 && localStorage.getItem(`${53}`) === '1') nRule = -1;
                if( nRule === 18 && localStorage.getItem(`${54}`) === '1') nRule = -1;
                if( nRule === 19 && localStorage.getItem(`${55}`) === '1') nRule = -1;            
                if( nRule === 20 && localStorage.getItem(`${101}`) === '1') nRule = -1;            
                title =  item.title.substring(6) + (nRule === -1?' - 완료 했습니다.':''); 
            }
            switchs[idx] = { item: item, title:title, icon: nIcon, rule: nRule, x: nX, y:nY, type: nType};
        }
        else{
            switchs[idx] = { item: item, title:title, icon: nIcon, rule: nRule, x: nX, y:nY, type: nType};
        }
            
    });

    let step = 0;
    return  (
        <Box component="div" sx={{ position:"absolute", top: '50%', left:'5%',display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height:'40%', width: '90%', bgcolor: 'transparent'}}>
        {
        switchs.map((sw:any,idx:any) => {
            
            let cardID = sw.item.card;
            if( cardID === 'none') cardID = prevCardID.current;
            else prevCardID.current = cardID;
            //if( item.id === select )

            //console.log('SelectList->id:'+id+',nIcon:'+nIcon);
            // (nIcon===9 && id === '')?'contained':
            if( sw.type === 1 && (sw.x === 'r' || sw.x === 'l') ){
                return (
                    <Box component="div" sx={{ position: 'absolute', top: (sw.y) +'%', width: '100%', height: '15%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                        <Grid container spacing={1}>
                        <Grid item xs={6}>                           
                            {sw.x === 'l' && <Button variant="text" onClick={() => sw.rule === -1?{}:selectCase(sw.item.id, cardID, sw.item.outputs, sw.item.pt, sw.item.wt)} 
                                sx={{width:'100%',zIndex:100, display: 'flex', direction: 'row', justifyContent: 'flex-start', height: '35px' }} startIcon={<ArrowBackIcon />}>{sw.title}</Button>}
                        </Grid>
                        <Grid item xs={6}>
                            {sw.x === 'r' && <Button variant="text" onClick={() => sw.rule === -1?{}:selectCase(sw.item.id, cardID, sw.item.outputs, sw.item.pt, sw.item.wt)} 
                                sx={{width:'100%',zIndex:100, display: 'flex', direction: 'row', justifyContent: 'flex-end', height: '35px' }} endIcon={<ArrowForwardIcon />}>{sw.title}</Button>}
                        </Grid>
                        </Grid>
                    </Box>
                );
            }
            else{
                step++;
                return  (
                    <Box component="div" sx={{ position: 'absolute', 
                        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                        top: (step*20-10)+'%', width: '100%', height: '10%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <Button key={sw.item.title} 
                        variant={(sw.icon===9 && id === '')?'contained':'outlined'}
                        startIcon={getIcon(sw.icon)} 
                        color={(sw.icon===9)?'primary':'primary'} 
                        disabled={(sw.rule ===-1)?true:false}
                        sx={{
                            justifyContent:((sw.icon===9)?'center':'flex-start'), 
        //                    backgroundColor:(nIcon===9 && id === '')?'':( item.id === select )?'#f00':'#ffff', 
                            backgroundColor:(sw.icon===9 && id === '')?'':'#ffff', 
                            height:'35px',
                            width: '80%',
                            "&:hover": {
                                background: (sw.icon===9)?(id === '')?"#aaaa":"#fff":"#fff", // <- add here your desired color, for demonstration purposes I chose red
                                borderColor: '#83c3f7',
                            },
                            '&:active': {
                                boxShadow: 'none',
                                borderColor: '#00003f00',
                            },
                            '&:focus': {
                                boxShadow: 'none',
                                borderColor: '#83c3f7',
                            },
                        }}
                        onClick={() => sw.rule === -1?{}:selectCase(sw.item.id, cardID, sw.item.outputs, sw.item.pt, sw.item.wt)}>
                            {sw.icon !== 9 && <Title style={{fontSize: vert?'35px':fH+'px', marginTop:'0px'}}>{sw.title}</Title>}
                            {sw.icon === 9 && sw.title}
                    </Button>
                    </Box>
                )
                }
        })           
    }
    </Box>);
};

/*
0 0 0 0.2rem rgba(0,123,255,.5)'
{datas.map((item:any) => {
    return  <Button key={item.title} variant="outlined" 
    startIcon={getIcon()} color="success"  
    onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}>
    {item.title}
    </Button>;
    })}
    */

export default SelectList;