import React from "react";
//import ReactDOM from "react-dom";

//import {createRoot} from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import createApolloClient from './hoc/ApolloProvider';
import { createRoot } from "react-dom/client";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import "./index.css";
import App from "./App";
import { cardLink } from "./hoc/cache";

/*
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={createApolloClient} >
    <Router><div>
        <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/:type/:id/:index" element={<App/>}/>
          <Route path="/:type/:id" element={<App/>}/>
          <Route path="/:type" element={<App/>}/>
        </Routes></div>
    </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App history={history} />);

root.render(
    <React.StrictMode>
      <Router><div>
          <Routes>
            <Route path="/" element={<App history={history}/>} />
            <Route path="/:type/:id/:index" element={<App history={history}/>}/>
            <Route path="/:type/:id" element={<App history={history}/>}/>
            <Route path="/:type" element={<App history={history}/>}/>
          </Routes></div>
      </Router>
    </React.StrictMode>);
*/


const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <ApolloProvider client={createApolloClient} >
    <Router><div>
        <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/:type/:id/:index" element={<App/>}/>
          <Route path="/:type/:id" element={<App/>}/>
          <Route path="/:type" element={<App/>}/>
        </Routes></div>
    </Router>
    </ApolloProvider>
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();