import {useEffect, useRef, useState} from "react";
//import { useSpring, animated, useSprings, config } from '@react-spring/web';
//import { useTransitionCarousel } from 'react-spring-carousel'
 
import BaseCard from "../Card/BaseCard";
import { cardLink, cardView } from "../../hoc/cache";

import "./style.css";
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from "@use-gesture/react";
import clamp from 'lodash.clamp'
import useResize from "../../hook/useResize";

interface ICard {
  id: string, title: string; subtitle: string; data: string; category: string; 
  linkto: string; img: string;
}

interface FlowProps {
  flow: {
    id?: string;
    cards: Array<ICard>;
  };
  goCard: string;
}
/*

interface FlowProps {
    flow: {
      id?: string;
      cards: Array<{ id: string, title: string; subtitle: string; data: string; category: string }>;
    };
  }
*/
const CarouselFlow = (props: FlowProps) => {
    const { flow, goCard } = props;

    const [prevIndex, setPrevIndex] = useState(0);


//    let cards:any[] = [];
//    let idxList:any[] = [];
const [cards,setCards] = useState<ICard[]>([]);
const [idxList,setIdxList] = useState([]);

    useEffect(()=>{
      //console.log("StackFlow-useEffect***********************")

      initFlow();
    },[]);

    useEffect(()=>{
      //console.log('XXXXXXXXXXXXXXXXXXXXXX get goCard:'+goCard)
      if( goCard !== '')
        onSwitch(goCard);
    },[goCard]);

    const initFlow = async () => {

      let bStart = false;
      let startCardID = "";
      let flowData:any = null;
      await Promise.all(flow.cards.map( (v:ICard,i) => {

        if( v.subtitle !== null && v.subtitle.at(0) === '['){
          if( v.subtitle.at(2) === '0'){
            startCardID = v.id;
            bStart = true;
          }
          else
            cardLink().goCards[v.subtitle.at(2) as string] = v.id;
  
           v.subtitle = v.subtitle.substring(4);  
        }
        else if( !bStart && (v.subtitle === "시작" || v.subtitle === "Start Card") ){
          startCardID = v.id;
          bStart = true;
        }

        if( v.category === "assess" ){
          cardLink().editcardid = v.id;
          flowData = JSON.parse(v.data);
        }
      }));

      await Promise.all(flow.cards.map( (v:ICard,i) => {
        if( v.category === "switch" || v.category === "link" || v.category === "message" || v.category === "action"){
          if(v.data === ''){
            cards.unshift(v);
            return true;
          }

          let outputData = JSON.parse(v.data);
          if( v.id === startCardID ){
              cards.push(v);
              cardLink().cardid = v.id;
              cardLink().outputs = outputData.actions[0][1].rules;
          }
          else    
            cards.unshift(v);
          
          // apply flow link  
          //console.log( outputData.cases);
          if( flowData !== null ){
            outputData.cases.map( (connect:any) => {
              if( flowData.nodes[v.id].outputs[connect.id] === undefined){
                //console.log(connect.id +':'+flowData.nodes[v.id].outputs);
              }
              //console.log( connect.card + '->' + flowData.nodes[v.id].outputs[connect.id].connections[0].node);
              if( flowData.nodes[v.id].outputs[connect.id] !== null && flowData.nodes[v.id].outputs[connect.id].connections[0] !== undefined && flowData.nodes[v.id].outputs[connect.id].length !== 0)
                connect.card = flowData.nodes[v.id].outputs[connect.id].connections[0].node;  
            })
            v.data = JSON.stringify(outputData);
            //console.log( flowData.nodes[v.id].outputs);  
          }
        }
        return true;    
      }));
/*
      order.current = [];
      //await Promise.all(promises);
      await Promise.all(cards.map((card:ICard, index) => {
          if( card.id !== null )
              idxList[ card.id] = index;
          order.current.push(index);    
          return true;    
      }));
*/
      //order.current = [0,1,2,3,4,5,6,7];
      setPrevIndex( cards.length - 1);

    }
/*
    flow.cards.map( (v,i) => {
        if( v.category === "switch" || v.category === "link" || v.category === "message" || v.category === "action" || v.category === "assess")
            if( v.subtitle === "시작")
                cards.push(v);
            else    
                cards.unshift(v);
            if( v.category === "assess" ){
              cardLink().editcardid = v.id;
            }
                return true;    
    })
    //await Promise.all(promises);
    cards.map((card, index) => {
        if( card.cardid !== null )
            idxList[ card.id] = index;
        return true;    
    })
*/
    //let cWidth = cardView().width;
    //let cHeight = cardView().height;
    const { width : cWidth, height: cHeight } = useResize();

    const onSwitch = (cardId: any) => {
        if( cardId !== ''){
          let idx = idxList[cardId];
          //if( idx !== undefined )
          //  handleClick(idx);
        }
    }
    
    const index = useRef(0)
    const width = cWidth

    const [propsC, api] = useSprings(cards.length, i => ({
      x: i * width,
      y: 50,
      scale: 1,
      display: 'block',
    }))

    // @ts-ignore | Weird 
    const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (active && Math.abs(mx) > width / 2) {
        index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, cards.length - 1)
        cancel()
      }
      api.start(i => {
        if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
        const x = (i - index.current) * width + (active ? mx : 0);
        const y = 50;
        const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
        return { x, y, scale, display: 'block' }
      })
    })
    return (
      <div className='carousel'>
        {propsC.map((styles, i) => (
          <animated.div  {...bind()} key={i} style={{ ...styles, position: "absolute",}}>
            <animated.div style={{ ...styles }}>
            <BaseCard card={cards[i]} ctype={1} width={cWidth} height={cHeight}  onCaseSelect={(idx:any) => onSwitch(idx)}/>
            </animated.div>
          </animated.div>
        ))}
      </div>
    )
    /*
    const { 
      carouselFragment, 
      //slideToPrevItem, 
      //slideToNextItem 
    } = useSpringCarousel({
      itemsPerSlide: 1, 
      withLoop: true,
      items: cards.map((card) => ({
        id: card.id,
        renderItem: (
          <div style={{ width: cWidth}}>
            <BaseCard card={card} width={cWidth} height={cHeight}  onCaseSelect={(idx:any) => onSwitch(idx)}/>
          </div>
          
        ),
      })),
    });

    return (
      <div className='carousel'  style={{ position: "absolute", top:10, left:10, width: cWidth+5, height: cHeight }}>
        {carouselFragment}
      </div>
    );
    */
    
/*  
    const [activeItem, setActiveItem] = useState(0)
    const {
      carouselFragment,
      useListenToCustomEvent,
      slideToPrevItem,
      slideToNextItem
    } = useSpringCarousel({
      withLoop: true,
      items: cards.map((card,i) => ({
        id: i,
        renderItem: (
            <BaseCard card={card} width={cWidth} height={cHeight-100} onCaseSelect={(idx:any) => onSwitch(idx)}/>
        ),
      })),
    });

    useListenToCustomEvent((event) => {
      if (event.eventName === "onSlideStartChange") {
        setActiveItem(event.nextItem.index)
      } 
    });
    
    return (
      <div>
        <div>{activeItem + 1} / {cards.length}</div>
        <div>
          {carouselFragment}
        </div>
      </div>
    )
*/
}
/**
 * 
 *       <div>
        <div>{activeItem + 1} / {mockedItems.length}</div>
        <div>
          {carouselFragment}
        </div>
      </div>
 *             { cards[index].category == "switch" && <SwitchCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
            { cards[index].category == "link" && <LinkCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
            { cards[index].category == "message" && <LinkCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}


 *             onClick={() => handleClick(index)}
            { cards[index].category === "switch" &&<SwitchCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}

 */
export default CarouselFlow;
