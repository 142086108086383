
import "./style.css";

import { lazy, Suspense, useState} from 'react';
import Box from '@mui/material/Box';
import { cardView } from "../../hoc/cache";
import useResize from "../../hook/useResize";
import loadable from '@loadable/component'

interface ResizableFrameProps {
  src: string;
  title: string;
  path: string;
  cid: string;
  status: number;
  nextCard: any;
  iframeStyles?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Form = ({
  src,
  title,
  data,
  path,
  cid,
  status,
  nextCard,
  iframeStyles = {},
  ...rest
}: ResizableFrameProps): JSX.Element => {

    const { width : cWidth, height: cHeight } = useResize();

    //src = 'UserLogin'
    src = src.replace(/..\/Form\//i, '')

    if( src === 'CNTBase') src = 'BaseCNT'; 
    else if( src !== "YMLogin" && src !== "YMExamineeList" && src !== "YMTaskList" && src !== "YMTaskGuide" && src !== "YMTask" && src !== "YMExamineeRegist" && src !== "YMGroupRegist" 
      && src.indexOf("YM") === 0 ) src = 'YMBase'; 

    const ExForm = loadable(() => import(`../Form/${src}`)); //${src}`)); 
    return (
      <Suspense fallback={<div>Loading...</div>}>              
        <ExForm title={title} data={data} nextCard={(cIdx:number)=>nextCard(cIdx)}/>
      </Suspense>
    )
};
export default Form;
