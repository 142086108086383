import {Fragment, useEffect, useLayoutEffect, useRef, useState} from "react";
//import { useSpring, animated, useSprings, config } from '@react-spring/web';
//import { useTransitionCarousel } from 'react-spring-carousel'
import clonedeep from 'lodash.clonedeep'
 
import BaseCard from "../Card/BaseCard";
import { cardLink, cardView, userDatas } from "../../hoc/cache";

import "./style.css";
import { animated, useSpring, useSprings } from '@react-spring/web';
import { useDrag } from "@use-gesture/react";
import clamp from 'lodash.clamp'
import useResize from "../../hook/useResize";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { getFlowGQL } from "../../hoc/gql";
import CloseButton from "./CloseButton";



interface ICard {
  id: string, title: string; subtitle: string; data: string; category: string; 
  linkto: string; img: string;
}

interface FlowProps {
  flow: {
    id?: string;
    cards?: Array<ICard>;
    idx: number;
  };
}

const LayerSlideFlow = (props: FlowProps) => {
  const { flow} = props;
  const [prevIndex, setPrevIndex] = useState(0);
  const index = useRef(0)
  const userData = useReactiveVar(userDatas); // refresh UI by value
  const cardscale = 0.95;

  const fid = useRef('');
  const flowcards = useRef([]);
  const [cards,setCards] = useState<ICard[]>([]);
  const idxList = useRef<any>([]);    
  const historyData = useRef<any>(null);    
    
  const [ getFlowGQLHandler, getFlowGQLRequest] = useLazyQuery(getFlowGQL, {
    onCompleted: (data) => {
      if(data.getFlow.success === true && data.getFlow.flow.cards.length > 0) {
        flowcards.current = clonedeep(data.getFlow.flow.cards);
        fid.current = data.getFlow.id;
        initFlow();
        //console.log('ls:getF');     
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(()=>{
    //console.log("*lsf*********************flow.id:"+flow.id);
    //getFlowGQLHandler({variables: {id: userData.lfid},});
    //console.log('ls:ue');
    if( flow.id === '') return;
    if( flowcards.current.length === 0 || flow.id !== fid.current)
      getFlowGQLHandler({variables: {id: flow.id},});
    index.current = flow.idx;  
  },[flow.id]);

  useEffect(()=>{
    /*
    if( flow.id !== '') return;
    if( flowcards.current.length === 0 || userData.lfid !== fid.current)
      getFlowGQLHandler({variables: {id: userData.lfid},});
      
    index.current = userData.lidx;
    */
    //console.log('switch slide card :'+index.current+' <- '+userData.lidx);
    if( index.current === userData.lidx ) return;

    index.current = userData.lidx;
    shiftCards( 0, false);
    //console.log('ls:ue-idx');
  }, [userData.lidx]); 


  useLayoutEffect(() => {
    index.current = userData.lidx;
    //console.log('ls:lf');
  }, []); 

  const initFlow = async () => {
    //console.log("*lsf*********************flow.id:"+flow.id);

      let bStart = false;
      let startCardID = "";
      let flowData:any = null;
      
      await Promise.all(flowcards.current.map( (v:ICard,i) => {

        if( v.subtitle !== null && v.subtitle.at(0) === '['){
          if( v.subtitle.at(2) === '0'){
            startCardID = v.id;
            bStart = true;
          }
          else
            cardLink().goCards[v.subtitle.at(2) as string] = v.id;
  
           v.subtitle = v.subtitle.substring(4);  
        }
        else if( !bStart && (v.subtitle === "시작" || v.subtitle === "Start Card") ){
          startCardID = v.id;
          bStart = true;
        }

        if( v.category === "assess" ){
          //cardLink().editcardid = v.id;
          flowData = JSON.parse(v.data);
        }
        if( v.category === "message" && v.title === "ExamReport"){
          //cardLink().editcardid = v.id;
          const exData = JSON.parse(v.data);
          exData.content.history.map( (vx:any,ix:number) => {
            historyData.current[vx.cardid] = vx.select
          });
          
        }
      }));

      // sort by editor position
      if( flowData !== null ){
        var map:any = [];
        let d = Object.entries(flowData.nodes);
        for( const it of d)
          if( it[0].length > 20)
            map.push([it[0], (it[1] as any).position[0]]);
        map.sort((a:any, b:any) => a[1] - b[1]);
        await Promise.all( map.map((v:any,i:number)=>idxList.current[v[0]]=i));
        //console.log(' align menu list'+idxList.current);
      }

      await Promise.all(flowcards.current.map( (v:ICard,i) => {
        if( v.category === "switch" || v.category === "link" || v.category === "message" || v.category === "action"){

          if( flowData === null ){
            if(v.data === ''){
              cards.push(v);
              return true;
            }
  
            if( v.id === startCardID ){
                cards.unshift(v);
                //cardLink().cardid = v.id;
                //cardLink().outputs = outputData.actions[0][1].rules;
            }
            else    
              cards.push(v);
          }
          else {
            
            //flowData.nodes[v.id].position[0]
            cards[idxList.current[v.id]] = v;
            // apply flow link  
            let outputData = JSON.parse(v.data);
            //console.log( outputData.cases);
            outputData.cases.map( (connect:any) => {
              if( flowData.nodes[v.id].outputs[connect.id] === undefined){
                //console.log(connect.id +':'+flowData.nodes[v.id].outputs);
              }
              //console.log( connect.card + '->' + flowData.nodes[v.id].outputs[connect.id].connections[0].node);
              if( flowData.nodes[v.id].outputs[connect.id] !== null && flowData.nodes[v.id].outputs[connect.id].connections[0] !== undefined && flowData.nodes[v.id].outputs[connect.id].length !== 0)
                connect.card = flowData.nodes[v.id].outputs[connect.id].connections[0].node;  
            })
            v.data = JSON.stringify(outputData);
            //console.log( flowData.nodes[v.id].outputs);  
          }
        }
        return true;    
      }));

      setPrevIndex( cards.length - 1);

    }

    const { width : cWidth, height: cHeight } = useResize();
    
    const mobile = cardView().mobile;

    const onSwitch = (cardId: any) => {
        //console.log('*** onSwitch ***');
        if( cardId.length > 10){
          //console.log( 'ls:cardId:'+cardId)
          let idx = idxList.current[cardId];
          if( idx !== undefined )
            index.current = idx;
          //  handleClick(idx);
        }
        else{
          index.current = index.current+1;
        }
        shiftCards( 0, false);

    }
    
    const width = cWidth+10;

    const [propsC, api] = useSprings(cards.length, i => {
      //console.log('useStrings:'+ index.current)
      return ({
      x: i * width - index.current* width,
      y: 0,
      scale: cardscale,
      display: 'block',
    });
    })

    // @ts-ignore | Weird 
    const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
      //console.log('*** bind ***');
      if (active && Math.abs(mx) > width / 2) {
        index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, cards.length - 1)
        cancel()
      }
      shiftCards( mx,active);
    })

    const shiftCards = (mx:number,active:boolean) => {
      if( userData.fixed ) return; // stop slide
      api.start(i => {
        //console.log('shiftCards : api.start:'+ index.current)
        if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
        const x = (i - index.current) * width + (active ? mx : 0);
        const y = 0;
        const scale = active ? cardscale - Math.abs(mx) / width / 10 : cardscale;
        return { x, y, scale, display: 'block'}
      })
    }

    const closeLayer = (e:any) => {
      e.preventDefault();
      userDatas({...userDatas(), popLayer: '', lidx: 0});
    }

    const [wrapperSpring, setWrapperSpring] = useSpring(() => ({
      width: `${cWidth}px`,
      height: `${cHeight}px`,
    }));


    return (
      <Fragment>
        <animated.div style={wrapperSpring} className="wrapperLayer">        
        {propsC.map((styles, i) => (
          <animated.div  {...bind()} className="slideLayer" key={i} style={{ ...styles, width:cWidth, touchAction: mobile?'none':'none'}}>
            <CloseButton top={10} right={10} doClick={closeLayer}/>
            <BaseCard card={cards[i]} ctype={2} width={cWidth} height={cHeight}  onCaseSelect={(idx:any) => onSwitch(idx)}/>
          </animated.div>
        ))}
        </animated.div>
      </Fragment>
    )
}

export default LayerSlideFlow;
