import {useState, useEffect,Fragment} from "react";
import "./style.css";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {Typography, Box, CardContent, Divider, Chip, Grid} from '@mui/material'
import { cardLink, cardView, taskDatas, userDatas } from "../../hoc/cache";
import Assessment from "../Form/lab/Assessment";
import Profile from "../Form/lab/Profile";

import { Popover } from '@headlessui/react'
import { useReactiveVar } from "@apollo/client";

function MyPopover() {
  return (
    <Popover className="relative">
      <Popover.Button>Solutions</Popover.Button>

      <Popover.Panel className="absolute z-10">
        <div className="grid grid-cols-2">
          <a href="/analytics">Analytics</a>
          <a href="/engagement">Engagement</a>
          <a href="/security">Security</a>
          <a href="/integrations">Integrations</a>
        </div>

        <img src="/solutions.jpg" alt="" />
      </Popover.Panel>
    </Popover>
  )
}
              

interface CardProps {
  card: {
    id:string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
    linkto: string;
    img: string;
  };
  onCaseSelect: any;
}

const MessageCard = (props: CardProps) => {
  const { card, onCaseSelect } = props;
  const [subtitle, setSubtitle] = useState(""); 
  const { width : cWidth, height: cHeight } = useReactiveVar(userDatas);

  useEffect( ()=>
  {
    let sContent = card.subtitle;
    if( sContent === "End Card" ) sContent = "mbisq";
    setSubtitle(sContent);
  },[card.data]);

  if( card.data === null ) return<Fragment></Fragment>; 

  let cardData = (card.data ==='')?{cases:[],
    actions:[[card.subtitle, {type:0,action:["",""],rules:[]}, 2, ""]],
    content:{}}:JSON.parse(card.data);

  //console.log( "cardData:"+card.data);

  const reload = () =>  {
    window.location.reload();
  }


  //const cwidth = cardView().width;
  //const cheight = cardView().height;

  if( card.id === cardLink().cardid && cardData.actions[0][1].type === 1){
    
    let getPoint = () => {
      let point = 0;
      let results: any[] = [];
      for( let i = 0 ; i < cardLink().outputs.length; i++){
        results[cardLink().outputs[i]] = 0;
      }

      //taskDatas().history[props.card.id]
      Object.keys(taskDatas().history).forEach(async function(key) {
        const v = taskDatas().history[key];
        if( v.outputs === undefined || v.outputs.length < 2 ) return;
        point = point + v.pt*v.wt;
        for( let j = 0; v.outputs !== undefined && j < v.outputs.length; j++ ){
          if( !isNaN(results[v.outputs[j].output]))
            results[v.outputs[j].output] = results[v.outputs[j].output] +  v.outputs[j].pt*v.outputs[j].wt;
        }
      });
/*
      let hist = taskDatas().history;
      hist.map( (v:any,i:number) => {
        point = point + v.pt*v.wt;
        for( let j = 0; v.outputs !== undefined && j < v.outputs.length; j++ ){
          if( !isNaN(results[v.outputs[j].output]))
            results[v.outputs[j].output] = results[v.outputs[j].output] +  v.outputs[j].pt*v.outputs[j].wt;
        }
      })
      for( let i = 0 ; i < cardLink().history.length; i++){
        point = point + cardLink().history[i].pt*cardLink().history[i].wt;
        for( let j = 0; cardLink().history[i].outputs !== undefined && j < cardLink().history[i].outputs.length; j++ ){
          if( !isNaN(results[cardLink().history[i].outputs[j].output]))
            results[cardLink().history[i].outputs[j].output] = results[cardLink().history[i].outputs[j].output] +  cardLink().history[i].outputs[j].pt*cardLink().history[i].outputs[j].wt;
        }
      }
      //console.log(results);
*/
      let outs = [];
      for( let i = 0 ; i < cardLink().outputs.length; i++){
        
        let dName = cardLink().outputs[i] as string;
        if( cardLink().outputs[i] === 'plus') dName = '총점';

        outs.push({output:dName,point:results[cardLink().outputs[i]]});
      }
      //let data = JSON.stringify(cardLink().history);
      //alert( data);  
      //const report = Object.entries(results).map(([k,v]) => `${k} ${v}점  `);
      return outs;
    }

    cardData = {
      cases:[],
      actions:[[card.subtitle, {type:1,action:["",""],rules:"",cat:6}, 1, ""]],
      content:{
        flowcardid:'',
        history: taskDatas().history, //cardLink().history,
        assessment:getPoint(),
        examinee:{name:localStorage.getItem('exName'), bdate: localStorage.getItem('exBdate')}
      }
    };
  }

  if( localStorage.getItem('type') === 'slide' && cardData.content !== undefined && cardData.content.history !== undefined){
      let selected:any = [];
      cardData.content.history.map((v:any,i:any)=>{
      selected[v.cardid] = v.select;
    })
    cardLink().selected = selected;
  }

  const tHeight = cHeight * (cHeight< 700?30:37)/100 |0;
  return (
    <CardContent sx={{position: 'absolute',
    zIndex: 2,
    width: cWidth,
    height: cHeight,
    top: 0,
    padding: '0px',
    }}>
      <CardContent sx={{bottom: '0px',}} >
        <div className="row hr-1" >
          <div>{subtitle}</div>
        </div>
      </CardContent>

      <Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={{ xs: 0, sm: 0, md: 0 }} sx={{height:cHeight-69}}>
   
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} width={cWidth}>
          <Grid item xs={1}/>
          <Grid item xs={10}>
          <div className="overflow-y-auto row-s hr-11" style={{whiteSpace: 'pre-line'}}>
          {card.title}
          </div>
          </Grid>
          <Grid item xs={1}/>
        </Grid>

        {(cardData.actions[0][1].cat === 6 && cardData.actions[0][1].type === 1) && <Fragment>
          {/*<Assessment data={cardData} type={0}/>*/}
          <Button variant="contained" color="success" onClick={() => onCaseSelect("0000000000000000000000000000000000")} >
            다음
          </Button>
          </Fragment>
        }
        {(cardData.actions[0][1].cat === 6 && cardData.actions[0][1].type === 2) && <Profile data={cardData.content}/>}
        { ( cardData.actions[0][1].cat === 2 || cardData.actions[0][1].type === 0) &&
          <Fragment>
            <Typography variant="h5" component="h5" className="row hr-1">   
            </Typography>
       
          </Fragment>}
          
      </Stack>
    </CardContent>
  );
};

export default MessageCard;

/**
        {card.id === cardLink().cardid && <Fragment>
        { cardData.actions[0][1].type !== 1 &&
          <Fragment>
            <Box component="div" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h5" component="div">
                    {localStorage.getItem('name')}의 검사 결과입니다.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h6" component="div">
                    {card.subtitle}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" component="div">
                      양호
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                {outs.map((v,i)=>(
                  <Typography color="text.secondary" key={i} variant="body1">{v.output}:{v.point}</Typography>
                )) }
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ m: 2 }}>
                <Typography gutterBottom variant="body2">
                  keywords
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Chip label="우울" />
                  <Chip color="primary" label="ADHD" />
                  <Chip label="발달" />
                  <Chip label="치료" />
                </Stack>
              </Box>
            </Box>
            <GenerateHistory outs={outs}/>
            <Button variant="contained" color="success" onClick={() => reload()} >
                  다 시 하 기
            </Button>
          </Fragment>}

 */