import {Fragment, useEffect, useRef, useState} from "react";
import { useSpring, animated, useSprings, config } from '@react-spring/web';

import BaseCard from "../Card/BaseCard";
//import LinkCard from "../Card/LinkCard";
import { cardLink, cardView, connectorMQTT } from "../../hoc/cache";

import "./style.css";
import useResize from "../../hook/useResize";

const springConfig = {
    tension: 350,
    friction: 60,
    precision: 0.001,
    velocity: 0.001,
    clamp: true,
  };
  
const CARD_STACK_MARGIN = 3;
const DURATION = 300;
const BASE_Z_INDEX = 20;

const from = (i: number) => ({
y: -500,
scale: 1,
x: 0,
rotateZ: 0,
transformOrigin: "center",
zIndex: i + BASE_Z_INDEX,
});

interface ICard {
  id: string, title: string; subtitle: string; data: string; category: string; 
  linkto: string; img: string;
}

interface FlowProps {
    flow: {
      id?: string;
      cards: Array<ICard>;
    };
    goCard: string;
  }


const StackFlow = (props: FlowProps) => {
    const { flow, goCard } = props;

    const [prevIndex, setPrevIndex] = useState(0);

    //let cards:any[] = [];
    //let idxList:any[] = [];
    const [cards,setCards] = useState<ICard[]>([]);
    const [idxList,setIdxList] = useState([]);

    //let cWidth = cardView().width;
    //let cHeight = cardView().height;
    //const [cWidth, setWidth] = useState(cardView().width);
    //const [cHeight, setHeight] = useState(cardView().height);

    //setWidth(cardView().width);
    //setHeight(cardView().height);

    const { width : cWidth, height: cHeight } = useResize();
    //console.log('stackflow resize ************** ('+cWidth+'x'+cHeight+')');

    useEffect(()=>{
      //console.log('resize:'+cHeight);
      let idx = idxList[cardLink().cardid];
      if( idx !== undefined )
        resizeCards(idx);
    },[cHeight]);

    useEffect(()=>{
      //console.log('XXXXXXXXXXXXXXXXXXXXXX get goCard:'+goCard)
      if( goCard !== '')
        onSwitch(goCard);
    },[goCard]);

    useEffect(()=>{
      //console.log("StackFlow-useEffect***********************")

      initFlow();
    },[]);

    const initFlow = async () => {

      let bStart = false;
      let startCardID = "";
      let flowData:any = null;
      await Promise.all(flow.cards.map( (v:ICard,i) => {

        if( v.subtitle !== null && v.subtitle.at(0) === '['){
          if( v.subtitle.at(2) === '0'){
            startCardID = v.id;
            bStart = true;
          }
          else
            cardLink().goCards[v.subtitle.at(2) as string] = v.id;
  
           v.subtitle = v.subtitle.substring(4);  
        }
        else if( !bStart && (v.subtitle === "시작" || v.subtitle === "Start Card") ){
          startCardID = v.id;
          bStart = true;
        }

        if( v.category === "assess" ){
          cardLink().editcardid = v.id;
          flowData = JSON.parse(v.data);
        }
      }));

      await Promise.all(flow.cards.map( (v:ICard,i) => {
        if( v.category === "switch" || v.category === "link" || v.category === "message" || v.category === "action"){
          if(v.data === ''){
            cards.unshift(v);
            return true;
          }

          let outputData = JSON.parse(v.data);
          if( v.id === startCardID ){
              cards.push(v);
              cardLink().cardid = v.id;
              cardLink().outputs = outputData.actions[0][1].rules;
          }
          else    
            cards.unshift(v);
          
          // apply flow link  
          //console.log( outputData.cases);
          if( flowData !== null ){
            outputData.cases.map( (connect:any) => {
              if( flowData.nodes[v.id].outputs[connect.id] === undefined){
                //console.log(connect.id +':'+flowData.nodes[v.id].outputs);
              }
              //console.log( connect.card + '->' + flowData.nodes[v.id].outputs[connect.id].connections[0].node);
              if( flowData.nodes[v.id].outputs[connect.id] !== null && flowData.nodes[v.id].outputs[connect.id].connections[0] !== undefined && flowData.nodes[v.id].outputs[connect.id].length !== 0)
                connect.card = flowData.nodes[v.id].outputs[connect.id].connections[0].node;  
            })
            v.data = JSON.stringify(outputData);
            //console.log( flowData.nodes[v.id].outputs);  
          }
        }
        return true;    
      }));

      order.current = [];
      //await Promise.all(promises);
      await Promise.all(cards.map((card:ICard, index) => {
          if( card.id !== null )
              idxList[ card.id] = index;
          order.current.push(index);    
          return true;    
      }));

      //order.current = [0,1,2,3,4,5,6,7];
      setPrevIndex( cards.length - 1);

    }

    const order = useRef(cards.map((_, index) => index));
    
    const [wrapperSpring, setWrapperSpring] = useSpring(() => ({
      width: `${cWidth}px`,
      height: `${cHeight}px`,
    }));

    // initial operation
    const [switchCardSprings, setSwitchCardSprings] = useSprings(
      cards.length,
      (index) => {
        //console.log('setSwitchCardSprings  ----------------');
        //console.log('stackflow init ************** ('+cWidth+'x'+cHeight+')');
        const idx = order.current.indexOf(index);
        //console.log("initial:"+index+"/"+idx+"/"+cards.length+"*"+order.current.indexOf(index));
        return {
          from: { ...from(idx), y: cHeight },
          to: async (animate) => {
            await animate({
              y: cHeight + 15 + idx * CARD_STACK_MARGIN,
              rotateZ: 0,
              zIndex: idx + BASE_Z_INDEX,
              immediate: (key: string) => key === "zIndex",
              config: { duration: DURATION * 0.1 },
            });
            await animate({
              y: idx === cards.length - 1 ? 0 : cHeight + 15 + idx * CARD_STACK_MARGIN,
              rotateZ: 0, //idx === cards.length - 1 ? -3 : idx % 2 ? -1 : 1,
              zIndex: idx + BASE_Z_INDEX,
              immediate: (key: string) => key === "zIndex",
              delay: DURATION * 0.7,
              config: { ...config.gentle },
            });
          },
          immediate: (key: string) => key === "zIndex",
          config: {
            duration: DURATION * 0.1,
          },
        };
      }
    );
    

    const resizeWrapper = () => {
      //console.log('stackflow resizeWrapper ************** ('+cWidth+'x'+cHeight+')');
      setWrapperSpring.stop();
      setWrapperSpring.start({
        from: {
          height: `${cHeight}px`,
        },
        to: async (animate) => {
          await animate({
            height: `${cHeight + CARD_STACK_MARGIN * 1.2}px`,
          });
          await animate({
            height: `${cHeight}px`,
          });
        },
        config: {
          ...springConfig,
          duration: DURATION,
        },
      });
    };

    const resizeCards = (index:any) => {
      //console.log('stackflow resizeCards ************** ('+cWidth+'x'+cHeight+')');
      setSwitchCardSprings.stop();
      setSwitchCardSprings.start((itemIndex) => {
        //console.log("click:"+cardIndex+"/c:"+index+"/p:"+prevIndex+"/"+itemIndex);
        if (itemIndex !== index ){
          //console.log('card open : '+cards[index].id);
          return {
            to: async (animate:any) => {
              await animate( {
                y: cHeight+5,
                rotateZ: 0,
                config: springConfig,
              }
              )
            },
            config: { duration: DURATION * 0.8 },
          }
        }
      });
    };

    const handleOrder = (index: number) => {
      const oldOrder = [...order.current];
      const newOrder = [...order.current];
      newOrder.splice(index, 1);
      newOrder.push(order.current[index]);
      order.current = newOrder;
      return {
        oldOrder,
        newOrder,
        getOldOrderIndex: function (index: number) {
          return this.oldOrder.indexOf(index);
        },
        getNewOrderIndex: function (index: number) {
          return this.newOrder.indexOf(index);
        },
      };
    };
  
    const handleClick = (cardIndex: number) => {
      //console.log('stackflow handleClick ************** ('+cWidth+'x'+cHeight+')');
      // click animation logic
      const index = cardIndex; //order.current.indexOf(cardIndex);

      if (index < cards.length) {
        const orderDetails = handleOrder(index);
        resizeWrapper();
        setSwitchCardSprings.stop();
        setSwitchCardSprings.start((itemIndex) => {
        //console.log("click:"+cardIndex+"/c:"+index+"/p:"+prevIndex+"/"+itemIndex);

          if (itemIndex === prevIndex ){
            return {
              from: {
                y: 0,
                rotateZ: 0,
                config: { duration: DURATION * 0.1 },
              }, 
              to: async (animate:any) => {
                await animate( {
                  y: cHeight + 15 + prevIndex * CARD_STACK_MARGIN, // - prevIndex*CARD_STACK_MARGIN,
                  rotateZ: 0,
                  config: springConfig,
                }
                )
              },
              config: { duration: DURATION * 0.8 },
            }
          } 
          else if (itemIndex === index ){
            //console.log('card open : '+cards[index].id);
            connectorMQTT().cid = cards[index].id;
            return {
              from: {
                y: cHeight + 15 + index * CARD_STACK_MARGIN,
                rotateZ: 0,
                config: { duration: DURATION * 0.1 },
              }, 
              to: async (animate:any) => {
                await animate( {
                  y: 0,
                  rotateZ: 0,
                  config: springConfig,
                }
                )
              },
              config: { duration: DURATION * 0.8 },
            }
          } 
        });
      }
      setPrevIndex(index);
    };    

    const onSwitch = (cardId: any) => {
        if( cardId !== '' && cardLink().cardid !== cardId){
          let idx = idxList[cardId];
          if(cardId.length === 24)
            cardLink().cardid = cardId;
          //console.log('card switch:'+cardId+"-"+idx+"/"+cardLink().cardid);
          if( idx !== undefined )
            handleClick(idx);
        }
    }

    return (<Fragment>
       {cards.length !== 0 && <animated.div style={wrapperSpring} className="wrapper">
        {switchCardSprings.map((styles, index) => (
          <animated.div
            style={{
              ...styles,
              position: "absolute",
              width: cWidth,
            }}
            key={"card-" + index}
          >
            <BaseCard card={cards[index]} ctype={1} width={cWidth} height={cHeight} onCaseSelect={(idx:any) => onSwitch(idx)}/>
          </animated.div>
        ))}
      </animated.div>}
      </Fragment>
    )
}
/**
 *             { cards[index].category == "switch" && <SwitchCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
            { cards[index].category == "link" && <LinkCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
            { cards[index].category == "message" && <LinkCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}


 *             onClick={() => handleClick(index)}
            { cards[index].category === "switch" &&<SwitchCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}

 */
export default StackFlow;
