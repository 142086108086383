import {useState, useEffect,Fragment} from "react";
import "./style.css";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {Typography, CardContent, Divider} from '@mui/material'
import { cardLink, cardView, taskDatas } from "../../hoc/cache";
import {
  useMutation,
} from "@apollo/client";
import { generateHistoryGQL } from "../../hoc/gql";



function GenerateHistory(props:any) {

  const [generateHistory, { loading, error, data }] = useMutation(generateHistoryGQL, 
    {      
      onCompleted: (data) => {
        const url = '/slide/'+data.generateHistory.id;
        window.open(url, '_blank', "height=800,width=550");    
      }
    },
  );

  useEffect(()=>{
  },[]);

  if (loading) return <p>generate History ...</p>;
  if (error) return <p>Error!: {error.message}</p>;
  //console.log(data);




  function openFlow() {
    //const url = 'https://liflo.io/link/'+cardLink().flowid+'/'+cardLink().cardid;
    const result = {
      history :  taskDatas().history, //cardLink().history,
      flowcardid:'', //cardLink().flowcardid,
      assessment: props.outs,
      examinee:{
        name: localStorage.getItem('exName'),
        bdate: localStorage.getItem('exBdate') 
      }
    };

    const dataJson = {
       cases:[],
       actions:[["",{type:1,action:[],rules:[]},6,""]], // cat:6(form) - type:1(assessment result)
       content:result, 
    }

    //{\"cases\":[{\"id\":\"Connect_1\",\"title\":\"다음\",\"flow\":\"63018ded033fa30319e2d9af\",\"card\":\"63107c0d0d96270310ff8489\",\"img\":\"\",\"pt\":1,\"wt\":1}],\"actions\":[[\"\",{\"type\":3,\"action\":[\"\",\"\"],\"rules\":\"\"},1,\"\"]]}
    //json.flowcardid = cardLink().flowcardid;
    const jsonString = JSON.stringify(dataJson, null, "\t");
    //console.log("history data:"+jsonString);
    let cardid = localStorage.getItem('exID');


    generateHistory({
                  variables: { 
                    "id": cardid, 
                    "input": {
                            //"title": "",
                            //"category": "assess",
                            "owner": cardid,
                            "linkto": "", //cardLink().flowid,
                            "data": jsonString}
                    }
                });
  }

  return(
    <Button variant="contained" color="success" onClick={() => openFlow()} >
    결과 카드
    </Button>
);
}


interface CardProps {
  card: {
    id:string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
    linkto: string;
    img: string;
  };
  onCaseSelect: any;
}

const EditorCard = (props: CardProps) => {
  const { card, onCaseSelect } = props;

  if( card.data === null ) return<Fragment/>; 


  //console.log( "cardData:"+card.data);
  const cardData = JSON.parse(card.data === ''?"{\"cases\":[],\"actions\":[[\"\",{\"type\":0,\"action\":[\"\",\"\"],\"rules\":\"\"},1,\"\"]]}":card.data);  
  //console.log( "cardData:"+card.data);
  if( localStorage.getItem('type') === 'slide' && cardData.content !== undefined && cardData.content.history !== undefined){
    let selected:any = [];
    cardData.content.history.map((v:any,i:any)=>{
      selected[v.cardid] = v.select;
    })
    cardLink().selected = selected;
  }
    
  const doEditor = () =>  {
    let host = 'https://fe.liflo.io';
    if( cardLink().dev ) host = 'http://localhost:3000';
    const url = host + '/card/0/'+card.id;
    window.open(url, '_blank', "height=800,width=1100");
  }

  const doExecute = () =>  {
    let host = '';
    if( cardLink().dev ) host = 'http://localhost:3006';
    const url = host + '/flow/'+card.linkto;
    window.open(url, '_blank', "height=800,width=550");
  }

  const cwidth = cardView().width;
  const cheight = cardView().height;

  return (
    <CardContent sx={{position: 'absolute',
    zIndex: 2,
    width: cwidth-33,
    height: cheight-40,
    top: 0,
    padding: '5px',
    }}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={{ xs: 2, sm: 1, md: 1 }} sx={{height:cheight-40}}>
   
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={{ xs: 2, sm: 2, md: 2 }}>
          <Typography variant="h4" component="h5" display="block" style={{whiteSpace: 'pre-line'}}>
          {card.title}
          </Typography>
          <Divider/>
          <Typography variant="h5" component="h5" className="row hr-1">
          {card.subtitle} card
          </Typography>
          <Typography variant="h6" component="h6" className="row hr-4">
          version : {cardLink().version} / {cardData.id}
          </Typography>
          <Divider/>
          <Button variant="contained" color="success" onClick={() => doExecute()} >
                실 행 하 기 
          </Button>
          <Button variant="contained" color="success" onClick={() => doEditor()} >
                편 집 하 기 
          </Button>
        </Stack>
      </Stack>
    </CardContent>
  );
};

export default EditorCard;
